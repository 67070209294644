
import { mapActions } from 'vuex';
import { Swiper, Pagination } from "swiper";
import axios from "axios";
import { ARTICLE_DEFAULT_IMG, getZoomCropImage } from '~/utils/image';

export default {
    name: "new-article-container",
    props: {
        article: Object,
    },
    data() {
        return {
            like_status: this.article?.like ? this.article?.like : this.article?.relation?.like,
            like_count: this.article?.like_count ? this.article?.like_count : this.article?.counts?.like_count,
            random_default_img: `https://www.pixnet.net/img/public/default/img_default_${Math.floor(Math.random() * 3)}.png`,
            block_click_status: false,
            isAdult: false
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        isBlogArticle() {
            return this.article.type === 'blog_article';
        },
        haveImage() {
            // 修改Api的image欄位，如抓不到圖並回傳notfound.png網址時，讓頁面顯示為無圖片 #110150
            if (this.article.image) {
                // 如之後條件有變更或是圖片有更改，可更換"if"或新增"else if"條件
                if (this.article.image.indexOf("https://s.pixfs.net/common/ppage/image/notfound.png") > -1) {
                    return false; // Api抓不到圖並回傳notfound.png
                } else {
                    return true; // Api正常抓得到圖
                }
            } else {
                return false; // image欄位為空值
            }
        },
        haveVideo() {
            return Boolean(this.article.video);
        },
        dynamic_class() {
            return `new-article-card-_${this.article.id}`;
        },
        dynamic_pagination_id() {
            return `new-article-card-pagination_${this.article.id}`;
        },
        multi_img() {
            return !this.isBlogArticle && this.article.images && this.article.images.length > 1
        },
        article_link() {
            return this.isBlogArticle ? this.article.link
                : `https://comments.pixnet.net/personal-post/${this.article?.author?.uniqid}/${this.article.id}`;
        },
    },
    methods: {
        ...mapActions("pa", [
            "paEvent",
            "paViewEvent"
        ]),
        getZoomCropImage,
        toggleLikeArticle(status, type) {
            this.setBlockToAvoidTooMoreClick();
            if (status) {
                this.disLikeArticle(type);
            } else {
                this.likeArticle(type);
            }
        },
        setBlockToAvoidTooMoreClick() {
            this.block_click_status = true;
            setTimeout(() => {
                this.block_click_status = false;
            }, 1000)
        },
        imgOnError(event) {
            event.target.src = this.random_default_img;
        },
        likeArticle(type) {
            if (this.is_login && this.member_token != null) {
                if (this.isBlogArticle) {
                    const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                    const object_ids = {
                        service: 'blog-article',
                        comment_id: this.article.author.uniqid + '-blog-article://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                        push_count: 1
                    }
                    const reqInstance = axios.create({
                        headers: {
                            Authorization: "Bearer " + this.member_token,
                        }
                    });
                    const req = reqInstance.post(API_url, object_ids);
                    req.then((e) => {
                        if (!e.data.error && e.status === 200) {
                            this.like_status = true;
                            this.like_count = parseInt(this.like_count) + 1;
                            if (this.$route.name === 'v3mobile') {
                                this.paClickLikeEvent(type);
                            }
                        }
                    })
                        .catch((e) => {
                            console.log(e)
                        })
                } else {
                    const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                    const object_ids = {
                        service: 'personal-post',
                        comment_id: this.article.author.uniqid + '-personal://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                        push_count: 1
                    };
                    const reqInstance = axios.create({
                        headers: {
                            Authorization: "Bearer " + this.member_token,
                        }
                    });
                    const req = reqInstance.post(API_url, object_ids);
                    req.then((e) => {
                        if (!e.data.error && e.status === 200) {
                            this.like_status = true;
                            this.like_count = parseInt(this.like_count) + 1;
                            if (this.$route.name === 'v3mobile') {
                                this.paClickLikeEvent(type);
                            }
                        }
                    })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        disLikeArticle(type) {
            if (this.is_login && this.member_token != null) {
                if (this.isBlogArticle) {
                    const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                    const params = {
                        service: 'blog-article',
                        comment_id: this.article.author.uniqid + '-blog-article://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                    }
                    const headers = {
                        Authorization: "Bearer " + this.member_token,
                    }
                    const req = axios.delete(API_url, { headers: headers, params: params, responseType: "json" });
                    req.then((e) => {
                        if (!e.data.error && e.status === 200) {
                            this.like_status = false;
                            this.like_count = parseInt(this.like_count) - 1;
                            if (this.$route.name === 'v3mobile') {
                                this.paClickLikeEvent(type, '取消');
                            }
                        }
                    })
                        .catch((e) => {
                            console.log(e)
                        })
                } else {
                    const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                    const params = {
                        service: 'personal-post',
                        comment_id: this.article.author.uniqid + '-personal://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                    };
                    const headers = {
                        Authorization: "Bearer " + this.member_token,
                    };
                    const req = axios.delete(API_url, { headers: headers, params: params, responseType: "json" });
                    req.then((e) => {
                        if (!e.data.error && e.status === 200) {
                            this.like_status = false;
                            this.like_count = parseInt(this.like_count) - 1;
                            if (this.$route.name === 'v3mobile') {
                                this.paClickLikeEvent(type, '取消');
                            }
                        }
                    })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        setSwiper() {
            Swiper.use([Pagination]);
            this.swiper = new Swiper(`.${this.dynamic_class}`, {
                direction: "horizontal",
                slidesPerView: 1,
                slidesPerGroup: 1,
                observer: true,
                observeParents: true,
                modules: [Pagination],
                pagination: {
                    el: `#${this.dynamic_pagination_id}`,
                    type: "bullets",
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 10
                },
            });
        },
        paClickEvent(paAction, type, blogger, bloggerId, link, index) {
            if (this.$route.name === 'v3mobile') {
                const dataIndex = (1.0000 + index * 0.0001).toFixed(4);
                const paType = type === 'blog_article' ? '文章' : '短貼文';
                const paTypeName = type === 'blog_article' ? 'article_card' : 'post_card';
                const payload = {
                    device: 'mobile',
                    action: `${paAction}::${paType}::${paTypeName}::`,
                    label: `${blogger}/${bloggerId}::${link}`,
                    value: dataIndex
                }
                this.paEvent(payload);
            }
        },
        paCheckViewEvent() {
            if (this.$refs.newArticleCard) {
                const paType = this.$refs.newArticleCard.dataset.type === 'blog_article' ? '文章' : '短貼文';
                const paTypeName = this.$refs.newArticleCard.dataset.type === 'blog_article' ? 'article_card' : 'post_card';

                const payload = {
                    element: this.$refs.newArticleCard,
                    device: 'mobile',
                    action: `imps::${paType}::${paTypeName}::`,
                    label: `${this.$refs.newArticleCard.dataset.user}/${this.$refs.newArticleCard.dataset.userId}::${this.$refs.newArticleCard.href}`
                }
                this.paViewEvent(payload);
            }
        },
        paClickLikeEvent(type, like = '') {
            const paType = type === 'blog_article' ? '文章' : '短貼文';
            const paTypeName = type === 'blog_article' ? 'article_card' : 'post_card';
            const payload = {
                device: 'mobile',
                action: `happix::${paType}${like}關注::${paTypeName}::`
            }
            this.paEvent(payload);
        }
    },
    mounted() {
        this.isAdult = this.article.is_adult == '1';
        this.setSwiper();
        if (this.$route.name === 'v3mobile') {
            this.paCheckViewEvent();
        }
    },
}
