
import { Swiper, Pagination } from "swiper";
import { showAdJs } from '~/utils/show_ad_js';

export default {
    name: "new-article-card-ad",
    props: {
        article: Object,
    },
    data() {
        return {
            divId: `falcon_${this.article.ad_type}_${this.article.ad_id}`,
            random_default_img: `https://www.pixnet.net/img/public/default/img_default_${Math.floor(Math.random() * 3)}.png`,
            have_image: Boolean(this.article.picture),
        }
    },
    computed: {
    },
    methods: {
        // TODO: 預留未來事件追蹤用
        // ...mapActions("pa", [
        //     "paEvent",
        //     "paViewEvent"
        // ]),
		showAdJs,
        imgOnError(event) {
            event.target.src = this.random_default_img;
        },
        setSwiper() {
            Swiper.use([Pagination]);
            this.swiper = new Swiper(`.${this.dynamic_class}`, {
                direction: "horizontal",
                slidesPerView: 1,
                slidesPerGroup: 1,
                observer: true,
                observeParents: true,
                modules: [Pagination],
                pagination: {
                    el: `#${this.dynamic_pagination_id}`,
                    type: "bullets",
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 10
                },
            });
        },
        // TODO: 預留未來事件追蹤用
        // paClickEvent(paAction, type, blogger, bloggerId, link, index) {
        //     if (this.$route.name === 'v3mobile') {
        //         const dataIndex = (1.0000 + index * 0.0001).toFixed(4);
        //         const paType = type === 'blog_article' ? '文章' : '短貼文';
        //         const paTypeName = type === 'blog_article' ? 'article_card' : 'post_card';
        //         const payload = {
        //             device: 'mobile',
        //             action: `${paAction}::${paType}::${paTypeName}::`,
        //             label: `${blogger}/${bloggerId}::${link}`,
        //             value: dataIndex
        //         }
        //         this.paEvent(payload);
        //     }
        // },
        // paCheckViewEvent() {
        //     if (this.$refs.newArticleCard) {
        //         const paType = this.$refs.newArticleCard.dataset.type === 'blog_article' ? '文章' : '短貼文';
        //         const paTypeName = this.$refs.newArticleCard.dataset.type === 'blog_article' ? 'article_card' : 'post_card';

        //         const payload = {
        //             element: this.$refs.newArticleCard,
        //             device: 'mobile',
        //             action: `imps::${paType}::${paTypeName}::`,
        //             label: `${this.$refs.newArticleCard.dataset.user}/${this.$refs.newArticleCard.dataset.userId}::${this.$refs.newArticleCard.href}`
        //         }
        //         this.paViewEvent(payload);
        //     }
        // },
        // paClickLikeEvent(type, like = '') {
        //     const paType = type === 'blog_article' ? '文章' : '短貼文';
        //     const paTypeName = type === 'blog_article' ? 'article_card' : 'post_card';
        //     const payload = {
        //         device: 'mobile',
        //         action: `happix::${paType}${like}關注::${paTypeName}::`
        //     }
        //     this.paEvent(payload);
        // }
    },
    mounted() {
        this.setSwiper();
        // if (this.$route.name === 'v3mobile') {
        //     this.paCheckViewEvent();
        // }
        if (this.article.ad_type === 'falcon' && window.PIXFalcon !== undefined) {
            var pixAd;
            pixAd = new PIXFalcon();
            pixAd.placeAds(`#falcon_${this.article.ad_id}`);
        } else if (this.article.ad_type === 'js') {
			showAdJs(this.$refs[this.divId]);
        }
    },
}
