export function showAdJs (adElement) {
    if (!adElement) return;
    
    const scripts = adElement.querySelectorAll('script');
    scripts.forEach(script => {
        const adJs = document.createElement('script');
        [...script.attributes].forEach(attr => {
            if (attr.nodeName !== 'id') adJs.setAttribute(attr.nodeName, attr.nodeValue);
        });
        adJs.textContent = script.textContent;
        script.replaceWith(adJs);
    });
};
